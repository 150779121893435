<template>
    <div class="flex-box vertical content md">
        <div class="ass-detail" v-if="info">
            <h3 class="ass-detail-title">
                <span>{{info.list.title}} - </span>
                <open-data type="userName" :openid="info.user_id" />
<!--                <span v-if="type && type <= 2">[{{type == 1 ? '自评' : '主管评价'}}]</span>-->
            </h3>
            <div class="ass-detail-piece">
                <ass-list-info :info="item.list"></ass-list-info>
            </div>
            <div class="ass-detail-piece" style="margin-top: 16px">
                <div class="ant-descriptions-title">成绩详情</div>
                <div class="ass-detail-area">
                    <a-table
                        :columns="columns"
                        :data-source="info.rules"
                        :pagination="false"
                        :row-key="record => record.key || record.id"
                        bordered>
                        <template v-slot:action="text, record">
                            <template v-if="record.type == 1">
                                <span v-if="message.status == 1">{{ type == 1 ? record.selfScore : record.directorScore}}</span>
                                <a-input v-model="record.selfScore" placeholder="请输入分数" v-else-if="type == 1"></a-input>
                                <a-input v-model="record.directorScore" placeholder="请输入分数" v-else-if="type == 2"></a-input>
                            </template>
                            <span v-else>客观项自动计算</span>
                        </template>
                    </a-table>
                </div>
            </div>
        </div>
        <a-space class="detail-footer" v-if="message && message.status == 0">
            <a-button type="primary" @click="sub">提交</a-button>
        </a-space>
    </div>
</template>

<script>
    import AssListInfo from "./AssListInfo";
    import {getAssNormList, getSourceNorm, getSysRuleText, isSelfMessage, scoreRender} from "../common/hr/ass";
    import {isEmpty} from "../common/js/tool";

    export default {
        name: "AssDetail",
        components: {
            AssListInfo
        },
        props: {
          item: Object,
          message: Object
        },
        data() {
            return {
            }
        },
        computed: {
            // 自评还是主管评价
            type() {
                let message = this.message;
                if(message) {
                    return isSelfMessage(message) ? 1 : 2;
                } else {
                    return 3;
                }
            },
            columns() {
                const res = [
                    { title: '序号', dataIndex: 'index', width: 80, customRender: (value, row, index) => index + 1 },
                    { title: '评价指标', dataIndex: 'name', width: 160 },
                    {
                        title: '指标说明',
                        dataIndex: 'normList',
                        customRender: (value, row) => row.type == 1 ? row.detail : (value && value.length > 0 ?
                          <div>{ value.map((item, i) => <div>{getSysRuleText(item, row.assType, i)}</div>) }</div> : "无")
                    },
                    { title: '单项总分', dataIndex: 'percent', width: 120, customRender: value => value + '分'},
                ]
                if(this.type == 3) {
                    res.push(
                      { title: '自评', dataIndex: 'selfScore', customRender: scoreRender},
                      { title: '主管评价', dataIndex: 'directorScore', customRender: scoreRender},
                      { title: '实际得分', dataIndex: 'score', width: 100, customRender: value => !isEmpty(value) ? `${value}分` : "--" },
                    );
                } else {
                    res.push({ title: this.type == 1 ? '自评（百分制）' : '评分（百分制）', dataIndex: 'action', width: 160, scopedSlots: { customRender: 'action'} });
                }
                return res;
            },
            info() {
                let item = this.item;
                let message = this.message;
                if(item) {
                    let res = {...item};
                    if(message) {
                        let rules = [];
                        let subjectiveList = JSON.parse(res.detail);
                        if(message && message.detail) {
                            let detail = JSON.parse(message.detail);
                            subjectiveList = subjectiveList.map((r, i) => {
                                const d = getSourceNorm(detail, r);
                                let s = d || detail[i] || {};
                                return {...r, ...s};
                            })
                        }
                        rules = rules.concat(subjectiveList);
                        if(res.regular_config) {
                            let objectiveList = JSON.parse(res.regular_config);
                            if(res.regular_score) {
                                let objectiveScore = JSON.parse(res.regular_score);
                                if(objectiveScore) {
                                    objectiveList.forEach((item, i) => {
                                        item.score = objectiveScore[i];
                                    })
                                }
                            }
                            rules = rules.concat(objectiveList);
                        }
                        res.rules = rules;
                    } else {
                        res.rules = getAssNormList(res);
                    }
                    return res;
                } else {
                    return null
                }
            },
        },
        methods: {
            sub() {
                this.$confirm({
                    title: '提示',
                    content: '确定提交吗？',
                    onOk: () => {
                        let type = this.type;
                        if(type && type <= 2) {
                            let score = this.info.rules;
                            let key = type == 1 ? 'self' : 'director';
                            let tip = null;
                            if(score) {
                                for(let i = 0, l = score.length; i < l; i++) {
                                    let item = score[i];
                                    if(item[`${key}Num`] > 0) {
                                        let num = item[`${key}Score`] * 1;
                                        if(isNaN(num)) {
                                            tip = `请正确输入第${i + 1}项的评价分数`;
                                        } else if(num < 0) {
                                            tip = `第${i + 1}项的评价分数不能为负数`;
                                        } else if(num > 100) {
                                            tip = `第${i + 1}项的评价分数不能大于100`;
                                        }
                                        if(tip) {
                                            break;
                                        }
                                    } else {
                                        continue;
                                    }
                                }
                            } else {
                                tip = '请输入分数';
                            }
                            if(tip) {
                                this.$message.warning(tip);
                            } else {
                                let total = score.reduce((acc, val) => {
                                    const s = val[`${key}Num`] > 0 ? val.percent * val[`${key}Num`] * val[`${key}Score`] / 10000 : 0;
                                    return acc + s;
                                }, 0);
                                total = Math.round(total * 100) / 100;
                                let detail = score.map(item => {
                                    let res = { cat: item.cat, percent: item.percent };
                                    res[`${key}Score`] = item[`${key}Score`];
                                    if (item.id) {
                                        res.id = item.id;
                                    } else {
                                        res.id = item.key;
                                    }
                                    return res;
                                });
                                this.$axios({
                                    url: `/admin/evaluate-message/${this.message.id}`,
                                    method: 'PATCH',
                                    data: {
                                        score: total,
                                        detail: JSON.stringify(detail),
                                        status: 1,
                                    }
                                }).then(res => {
                                    this.$set(this.message, "score", res.score);
                                    this.$set(this.message, "detail", res.detail);
                                    this.$set(this.message, "status", res.status);
                                })
                            }
                        }
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .ass-detail {
        flex: 1;
        min-height: 0;
        overflow: auto;
    }
    .ass-detail-title {
        text-align: center;
        font-weight: 600;
    }
    .ass-detail-item {
        display: flex;
        padding: 4px 0;
        line-height: 1.5;
    }
    .ass-detail-label {
        flex-shrink: 0;
        width: 100px;
    }
    .ass-table {
        width: 100%;
        border: var(--border);
        tr, td, th {
            border: var(--border);
        }
        td, th {
            padding: 6px 12px;
            text-align: center;
        }
    }
</style>
